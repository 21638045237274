import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/PrizeDistribution _ceremony/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/PrizeDistribution _ceremony/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/PrizeDistribution _ceremony/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/PrizeDistribution _ceremony/4.jpg';
// import p5 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2023/RepublicDay/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/2023/RepublicDay/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/2023/RepublicDay/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/2023/RepublicDay/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/2023/RepublicDay/10.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const Valedictionceremony6to82023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
       
//       {
//           src: p6,
//           source: p6,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p7,
//           source: p7,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p8,
//           source: p8,
//           rows: 1,
//           cols: 1,
//       }, 
//       {
//           src: p9,
//           source: p9,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p10,
//           source: p10,
//           rows: 1,
//           cols: 1,
//       }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Prize Distribution Ceremony	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Class: 6 – 8    Date: 09 FEBRUARY 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Prize Distribution Ceremony for classes 6 to 8 was a memorable event that took place on Friday, 09 February 2024, at National Public School, Yeshwanthpur. The event aimed to recognize and celebrate the academic achievements, talents and efforts of students in various fields.  
                                    <br></br>
                                    The highlight of the day was the recognition of academic excellence. Students who excelled in academics were awarded certificates and trophies. One student from each grade received special honour for their outstanding academic performance. 
                                    <br></br>
                                    Beyond academics, the school also celebrated achievements in sports and various association activities. Students who had demonstrated exceptional skills in sports, arts, music and other extracurricular activities were applauded and awarded.
                                    <br></br>
                                    Special awards were presented to the students who exhibited leadership qualities, good behaviour and made outstanding contributions to the school community. These awards aimed to encourage values such as discipline, teamwork and social responsibility.
                                    <br></br>
                                    The event concluded with a heartfelt vote of thanks extended by a student representative. The student expressed gratitude to the school management, principal, teachers, parents and students for their collective efforts in making the academic year a success.
                                    <br></br>
                                    The event fostered a sense of pride, motivation and community spirit among the students to pursue excellence in all their endeavours.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Excellence is the gradual result of always striving to do best. ”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Valedictionceremony6to82023;